export default function PlayArrow({ className = "w-20" }) {
  return (
    <svg
      viewBox="0 0 15 22"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g strokeLinejoin="round">
        <g
          transform="translate(-712.000000, -1999.000000)"
          fill="currentColor"
          stroke="currentColor">
          <g transform="translate(405.000000, 1834.000000)">
            <g transform="translate(275.000000, 136.000000)">
              <polygon points="33 50 33 30 46.3333333 40.0388787"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
