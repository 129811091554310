import Video from "@/common/partials/video/video"
import Block from "@/components/wrappers/block"
import classNames from "classnames"
import { usePathData } from "@/lib/contexts/appContext"

function Youtube({ block }) {
  const { template } = usePathData()

  if (!block.attrs.id) {
    return null
  }

  let width = template === "article" ? "column" : "large"
  let gap = template === "article" ? "small" : block.attrs.blockGap ?? "normal"

  if (block.attrs?.blockWidth) {
    width = block.attrs?.blockWidth
  }

  return (
    <Block
      block={block}
      width={width}
      gap={gap}
      className={classNames(block.attrs.classList, "no-print")}>
      <Video className="video-wrapper" data={block.attrs} />
    </Block>
  )
}

export default Youtube
